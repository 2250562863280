* {
    margin: 0;
    padding: 0;
}

.flexBox {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
}

.groupListPart {
    box-sizing: border-box;
    background-color: #fff;
    flex: .2;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
    min-width: 330px;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 85vh;
}

.groupDitailsPart {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
    min-width: 400px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 0 10px;
    padding-bottom: 20px;
    height: 85vh;
}

.allPassesPart {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
    min-width: 400px;
    margin-bottom: 10px;
    padding: 0 10px;
    height: 85vh;
}

.groupListHeader {
    box-sizing: border-box;
    width: 100%;
    padding: 13px 10px;
    border-bottom: 1px solid #E7E8EC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.passListTitle {
    font-weight: 500;
}

.passListBox {
    display: flex;
}

.sortOrderBtn button {
    border: none;
    padding: 4px 10px;
    background-color: #14628E;
    color: white;
    border-radius: 6px;
    font-size: 14px;
    margin-right: 5px;
}

.sortOrderDone button {
    border: none;
    padding: 4px 10px;
    background-color: #51BC79;
    color: white;
    border-radius: 6px;
    font-size: 14px;
    margin-right: 5px;
}

.sortOrderBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}

.createGroupBtn button {
    border: none;
    padding: 4px 10px;
    background-color: #14628E;
    color: white;
    border-radius: 6px;
    font-size: 14px;
}

.createGroupBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}

.overflowBox {
    overflow-y: auto;
    max-height: 669px;
}

.groupItemsBox {
    display: flex;
    flex-direction: column;
    height: 90%;
    padding: 8px 12px;
}

.nameAndOrderCircle {
    display: flex;
}

.orderCircle {
    width: 16px;
    height: 16px;
    background-color: #14628E;
    border-radius: 15px;
    color: white;
    text-align: center;
    margin-right: 5px;
}

.groupName {
    padding-left: 0;
}

.active {
    position: relative;
    width: 100%;
    background-color: #fff;
    margin-bottom: 6px;
    height: 37px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.08);
    padding: 0px 18px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
}

.inactive {
    position: relative;
    width: 100%;
    background-color: #fff;
    margin-bottom: 6px;
    height: 37px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.08);
    padding: 0px 18px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
}

.lineInactive {
    width: 100%;
    height: 3px;
    background-color: #CED1D8;
    margin-top: 5px;
    border-radius: 5px;
}

.lineActive {
    width: 100%;
    height: 3px;
    background-color: #51BC79;
    margin-top: 5px;
    border-radius: 5px;
}

.groupDetailsHeader {
    box-sizing: border-box;
    width: 100%;
    padding: 13px 10px;
    border-bottom: 1px solid #E7E8EC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.groupDetailsBtnBox {
    display: flex;
}

.renameBtn button {
    border: none;
    padding: 4px 4px;
    width: 110px;
    height: 37px;
    background-color: #14628E;
    color: white;
    border-radius: 6px;
    font-size: 14px;
}

.renameBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}

.dismissBtn button {
    border: none;
    padding: 4px 4px;
    background-color: #EDEEF0;
    width: 110px;
    height: 37px;
    color: #C34141;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 10px;
}

.dismissBtn button:hover {
    background-color: #C34141;
    transition: 0.4s;
    color: white;
}

.groupDetailsBox {
    background-color: #F7F8FA;
    flex: .9;
    max-height: 669px;
    margin-top: 10px;
    overflow-y: auto;
}

.addPassesBtnBox {
    width: 100%;
    flex: .1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.addPassesBtn button {
    width: 120px;
    height: 30px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 0px;
    border-radius: 5px;
}

.addPassesBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}

.groupDetailsTHeader {
    position: relative;
    position: sticky;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 37px;
    background-color: #14628E;
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 5px;
}

.sortOrderDetailBtn {
    position: absolute;
    right: 10px;
}

.sortOrderDetailBtn button {
    border: none;
    padding: 4px 6px;
    font-size: 14px;
    background-color: #3281AD;
    color: white;
}

.sortOrderDetailBtnDone {
    position: absolute;
    right: 10px;
}

.sortOrderDetailBtnDone button {
    border: none;
    padding: 4px 6px;
    font-size: 14px;
    background-color: #51BC79;
    color: white;
}

.sortOrderDetailBtn button:hover {
    transition: 0.4s;
    color: white;
    background-color: #082a3d;
}

.passName {
    width: 30%;
    margin-left: calc(3% + 10px);
}

.groupDetailsItemsBox {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.groupDetailsItem {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    height: 51px;
    margin: 3px 10px;
}

.orderCircleBig {
    width: 18px;
    height: 18px;
    background-color: #14628E;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 15px;
    margin-left: 5px;
}

.invisibleBox {
    width: 18px;
    height: 18px;
    font-size: 15px;
    margin-left: 5px;
}

.detailsItemName {
    width: 30%;
    margin-left: 3%;
    font-size: 14px;
}

.detailsItemStatusActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 18px;
    background-color: #B4E7B6;
    color: #213A22;
    border-radius: 5px;
    font-size: 14px;
}

.detailsItemStatusInactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 18px;
    background-color: #EEB6B6;
    color: #770B0B;
    border-radius: 5px;
    font-size: 14px;
}

.editBtn {
    position: absolute;
    right: 0;
    top: 50%;
    right: 50px;
    transform: translate(-50%, -50%);
}

.editBtn button {
    background-color: #3281AD;
    color: white;
    padding: 4px 5px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
}

.editBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}

.removeBtn button {
    position: absolute;
    border: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    background: url(../../../assets/delete.svg) no-repeat center;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
}

.allPassesHeader {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 10px;
    border-bottom: 1px solid #E7E8EC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unassignedBox {
    flex: .5;
    background-color: #F7F8FA;
    max-height: 325px;
    margin-top: 10px;
    overflow-y: auto;
}

.unassignedTitle {
    font-size: 14px;
    color: #6F7985;
    padding: 9px 14px;
    background-color: #F7F8FA;
    border-radius: 5px;
    margin-top: 10px;
}

.checkBox {
    margin: 0px 5px 0px 5px;
    display: flex;
    align-items: center;
}

.checkBox input {
    cursor: pointer;
}

.cancel {
    margin-bottom: 5px;
}

.cancel button {
    background-color: white;
    color: #14628E;
    border: 1px solid #14628E;
}

.confirm button {
    background-color: #51BC79;
}



@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
    .flexBox {
        height: auto;
    }

    .groupDetailsBox {
        height: 70%;
    }

    .groupDitailsPart {
        min-width: 500px;
        margin-right: 0px;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .flexBox {
        box-sizing: border-box;
        display: flex;
        height: auto;
        flex-wrap: wrap;
    }

    .groupListPart {
        background-color: #fff;
        flex: .2;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        margin-right: 10px;
        min-width: 250px;
        margin-bottom: 10px;
    }

    .groupDitailsPart {
        background-color: #fff;
        flex: .8;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        margin-right: 0px;
        min-width: 400px;
    }

    .allPassesPart {
        background-color: #fff;
        flex: 1;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        min-width: 400px;
    }

    .active {
        padding: 0px 5px;
    }

    .inactive {
        padding: 0px 5px;
    }
}

@media screen and (max-width:767px) {
    .flexBox {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        height: auto;
    }

    .groupListPart {
        background-color: #fff;
        flex: .3;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .groupDitailsPart {
        background-color: #fff;
        flex: .5;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .allPassesPart {
        background-color: #fff;
        flex: .5;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        padding-bottom: 20px;
    }

    .groupDetailsBtnBox {
        flex-direction: column;
    }

    .dismissBtn button {
        margin-top: 10px;
        margin-left: 0px;
    }

}