.displayItemBox {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 37px;
    background-color: #fff;
    margin: 3px 0px;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
    color:#3C3A3A;
    cursor: pointer;
}
.checkBox {
    position: absolute;
    right: 5px;
    width: 15px;
    height: 15px;
    border: 1px solid #d8d8d8;
    margin-right: 10px;
    background-color: #fff;
    -webkit-appearance:none;
    outline-style: none;
    cursor: pointer;
}
.checkBox:checked {
    background: url(../../assets/checkSmall.svg) no-repeat center;
}
.displayItemSubBox {
    position: relative;
    height: 26px;
    font-size: 13px;
    background-color:#F7F8FA ;
    color: #6F7985;
    border-bottom: 1px dashed #ebebeb ;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
