*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.box {
    flex: .7;
    margin-top: 90px;
    display: flex;
    justify-content: center;
}
.communitySelectBox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.innerBox {
    width: 50%;
}
.infoInputPart {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #E7E8EC;
}
.strip {
    width: 35px;
    height: 24px;
    background: url(../../assets/stripe.svg) no-repeat center;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}
.idInput {
    margin-top:14px ;
    flex: 1;
}
.idInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.idInput input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.inputBox {
    display: flex;
}
.generateBtn {
    align-self: flex-end;
}
.generateBtn button {
    width: 156px;
    height: 40px;
    background-color:#14628E ;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 10px;
}
.generateBtn button:hover {
    background-color: #082a3d;
    transition: 0.4s;
    color: white;
}
.text {
    margin-top: 5px;
    color: #222222;
}
.link {
    color: #14628E;
    cursor: pointer;
}
.saveBtnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 47px;
}
.saveBtn button {
    width: 190px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.saveBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .innerBox {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        flex: .8;
    }
    .innerBox {
        width: 80%;
    }
}
@media screen and (max-width:767px) {
    .box {
        flex: 1;
        margin-top: 37px;
    }
    .innerBox {
        width: 99%;
    }
    .idInput label {
        font-size: 14px;
    }
    .idInput input {
        height: 35px;
    }
    .generateBtn button {
        width: 136px;
        height: 35px;
        margin-top: 10px;
    }
    .inputBox {
        display: flex;
        flex-direction: column;
    }
    .text {
        font-size: 14px;
    }
}