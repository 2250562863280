.dropContainer {
    min-width: 257px;
    flex: 0.3333333;
    margin: 0px 10px;
    margin-bottom: 20px;
}
.titleBox {
    box-sizing: border-box;
    background-color: #F7F8FA;
    border-radius: 6px;
    padding: 11px 0px;
    padding-left: 10px;
    font-size: 14px;
    color:#6F7985;
}
.dropArea {
    background-color:#F7F8FA ;
    margin-top: 13px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 40px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .dropContainer {
        min-width: 310px;
    }
}