*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.preview {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}
.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.displayBox {
    margin-top: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
}
.displayTitleBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.reviewBtn {
    background: url(../../../assets/preview.svg) no-repeat left center ;
    width: 90px;
    height: 29px;
    padding-left: 15px;
    font-weight: 500;
    color: white;
    background-color:#51BC79;
    border: none;
    border-radius: 6px;
    cursor: pointer;
   
}
.reviewBtn:hover {
    background-color: #085c27;
    transition: 0.4s;
}
.displayTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    margin-left: 0px;
}
.colBox {
    display: flex;
    flex-wrap: wrap;
}
.col {
    min-width: 257px;
    flex: 0.5;
    margin-right: 30px;
    margin-bottom: 20px;
}
.titleBox {
    box-sizing: border-box;
    background-color: #F7F8FA;
    border-radius: 6px;
    padding: 11px 0px;
    padding-left: 10px;
    font-size: 14px;
    color:#6F7985;
    margin-bottom: 18px;
    margin-top: 20px;
}
.subCheckBoxs {
    border-radius: 6px;
    overflow: hidden;
}
.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .displayTitleBox {
      align-items: flex-start;
      flex-direction: column;
    }
    .reviewBtn {
       margin-left: 10px;
       margin-top: 10px;
    }
    .displayBox {
        width: 100%;
    }
    .displayTitle {
        font-size: 16px;
        margin-left: 10px;
    }
    .col {
        margin: 0px 10px;
    }
    .colBox {
        display: flex;
        flex-direction: column;
    }
}