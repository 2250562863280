* {
    margin: 0;
    padding: 0;
   }
.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
    padding-bottom: 300px;
}
.box {
    display: flex;
   flex: 0.8;
   flex-direction: row;
    border-radius: 5px;
    margin-top: 130px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    background-color: #fff;
}

.box__right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.5;
    padding-top: 185px;
    padding-right: 107px;
    padding-bottom: 77px;
    border-radius: 0 20px 20px 0;
}
.box__right button {
    background-color: #3281AD;
    border-radius: 5px;
    color: white;
}
.box__right button:hover {
    transition: .4s;
    background-color: #14628E;
}
.box__left {
    display: flex;
    flex-direction: column;
    position: relative;
    flex:0.5;
    overflow: hidden;
    padding-top: 56px;
    padding-left: 107px;
    padding-bottom: 77px;
    border-radius: 5px 0 0 5px;
    background-image: linear-gradient(#3281AD,#14628E);
}

.box__mainTitleLeft {
    display: flex;
    align-items: flex-end;
    flex: 2;
    font-size: 24px;
    font-weight: 600;
    max-width: 161px;
    margin-bottom: 23px;
    margin-left: 40%;
    margin-right: 30%;
    /* display: none; */
}
.box__btnLeft {
    display: flex;
    margin-left: 40%;
    margin-right: 30%;
    flex-shrink: 0;
    /* display: none; */
}

.box__img {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 400px;
}


.box__titleBoxRight {
    display: flex;
    flex: 0.15;
    flex-direction:column;
    margin-left: 30%;
}


.box__mainTitleRight {
    max-width: 424px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 6px;
}

.box__paragraph {
    max-width: 424px;
    font-size: 14px;
    margin-bottom: 40px;
    flex: 0.7;
    line-height: 150%;
}

.box__subTitle {
    display: flex;
    flex: 0.05;
    margin-bottom:24px ;
    margin-left: 30%;
}
.inputLong{
    display: flex;
    flex: 0.1;
    flex-direction: column;
    margin-left: 30%;
}
.box__inputLong {
    display: flex;
    flex: 0.1;
    margin-bottom: 20px;
}
.box__inputField{
    flex: 1;
    max-width:337px ;
    min-width: 200px;
}
.box__inputField label {
    width: 100%;
    color: black;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.box__inputField input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.box__btnRight{ 
    display: flex;
    flex: 0.1;
}
.box__forgotPassWord {
    font-size: 12px;
    font-weight: 500;
    margin-right: 20%;
    color: black;
}

.flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30%;
    margin-top: 48px;
    max-width: 340px;
}
.flexBox a {
    text-decoration: none;
}
.alert {
    display: flex;
    margin-left: 30%;
}
.alertBox {
    flex: 0.7;
    min-width: 200px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) {
  
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
    
        flex: 1;
        flex-direction: row;
         margin-top: 130px;
     }
     .box__right {
        padding-top: 56px;
        padding-right: 87px;
    }
    .box__mainTitleLeft {
        margin-right: 20px;
    }
    .box__btnLeft {
        margin-right: 20px;
    }
    .flexBox {
        flex-wrap: wrap;
    }
    .box__forgotPassWord {
        margin-bottom: 10px;
    }
    .box__inputField{
        flex: 1;
    }
    .alertBox {
        flex: 1;
        min-width: 200px;
    }
    
}
@media screen and (max-width:767px) {
    .box {
        flex-direction: column!important;
        flex-wrap: wrap;
       flex: 1;
       flex-direction: row;
        border-radius: 0px;
        margin-top: 44px;
    }
    .box__right {
        flex: 1;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 77px;
        border-radius: 0;
        order: -1;
    }
    .box__left {
        border-radius: 0;
        padding-left: 20px;
    }
    .box__titleBoxRight {
        margin-left: 20px;
    }
    .box__subTitle {
        margin-left: 20px;
    }
    .inputLong{
        margin-left: 20px;
    }
    
    .flexBox {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
    }
    .box__forgotPassWord {
        margin-bottom: 10px;
    }
    .box__img {
        display: none;
        
    }
    .box__mainTitleLeft {
        margin-left: 20px;
        margin-right: 0;
    }
    .box__btnLeft {
        margin-left: 20px;
        margin-right: 0;
    }
    .alert {
        display: flex;
        margin-left: 20px;
    }
    .alertBox {
        max-width: 337px;
        min-width: 200px;
    }
    .container {
        padding-bottom: 0px;
    }
    .box__inputField label {
        font-size: 14px;
    }
    .box__inputField input {
     height: 35px;
    }
    .box__inputField{
        flex: .9;
        max-width:337px ;
        min-width: 200px;
    }
}