.subHeader {
    display: flex;
    align-items: center;
    height: 103px;
    border-radius: 0 20px 0 0;
    background-color: #3281AD ;
}
.circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 45px;
    margin-right: 17px;
}
.title {
    font-size: 35px;
    font-weight: 500;
}
.form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 94px;
    padding-bottom: 108px;
}
.innerForm {
    width: 56%;
}
.inputLong {
    margin-bottom: 25px;
}
.saveBtn {
    width: 40%;
}
.saveBtn button {
    background-color: #3281AD;
    color: white;
}
.saveBtn button:hover {
    background-color: #14628E;
}
.btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 150px;
}
.innerForm label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.innerForm input {
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .subHeader {
        border-radius: 0;
    }
    .innerForm {
        width: 80%;
    }
    .saveBtn {
        width: 200px;
    }
}
@media screen and (max-width:767px) {
    .circle {
        width: 16px;
        height: 16px;
        margin-left: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .subHeader {
        border-radius: 0;
        height: 60px;
    }
    .form {
        width: 100%;
        margin-top: 30px;
    }
    .saveBtn {
        width: 200px;
    }
    .inputLong {
        margin-bottom: 18px;
    }
    .innerForm {
        width: 80%;
    }
}