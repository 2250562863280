*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.box {
    flex: .7;
    margin-top: 90px;
    display: flex;
    justify-content: center;
}
.communitySelectBox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.innerBox {
    width: 50%;
}
.infoInputPart {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #E7E8EC;
}
.customTitle {
    color: #3C3A3A;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}
.flexBox {
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid #E7E8EC;
    margin-bottom: 14px;
}
.customBtnActive button{
    height: 40px;
    font-size: 15px;
    width: 180px;
    border-radius: 6px;
    border: none;
    background-color: #3281AD;
    border: 1px solid #D3D9DE;
    margin-right: 20px;
}
.customBtnInactive button{
    height: 40px;
    font-size: 15px;
    width: 180px;
    border-radius: 6px;
    border: none;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    color: #222222;
    margin-right: 20px;
}
.noActive button {
    height: 40px;
    font-size: 15px;
    width: 180px;
    border-radius: 6px;
    border: none;
    background-color: #3281AD;
    border: 1px solid #D3D9DE;
}
.noInactive button {
    height: 40px;
    font-size: 15px;
    width: 180px;
    border-radius: 6px;
    border: none;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    color: #222222;
}
.creditCardFeeTitle {
    color: #6F7985;
    font-weight: 400;
    margin-bottom: 9px;
}
.PercentageInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}
.PercentageInput input {
    box-sizing: border-box;
    background: url(../../assets/percentage.svg) no-repeat left;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 20px;
    border-radius: 6px;
}
.fixedInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}
.fixedInput input {
    box-sizing: border-box;
    background: url(../../assets/dollor.svg) no-repeat left;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 20px;
    border-radius: 6px;
}
.saveBtnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 47px;
}
.saveBtn button {
    width: 190px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.saveBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .innerBox {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        flex: .8;
    }
    .innerBox {
        width: 80%;
    }
}
@media screen and (max-width:767px) {
    .flexBox {
        flex-direction: column;
    }
    .box {
        flex: 1;
        margin-top: 37px;
    }
    .innerBox {
        width: 99%;
    }
    .PercentageInput label {
        font-size: 14px;
    }
    .PercentageInput input {
        height: 35px;
    }
    .fixedInput label {
        font-size: 14px;
    }
    .fixedInput input {
        height: 35px;
    }
    .customBtnActive button{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .customBtnInactive button{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    
  
}