* {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}

.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.priceBox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.priceTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.priceTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    margin-left: 10px;
}

.priceInfoBox {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1;
    margin: 0px 3%;
    min-width: 250px;
    margin-bottom: 30px;
}

.mainTitle {
    width: 100%;
    position: relative;
    font-weight: 400;
    color: #3C3A3A;
    font-size: 16px;
    margin-bottom: 10px;
}

.dot {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #3C3A3A;
}

/* set a price part */
.optionBtnsBox {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4px;
    border-bottom: 1px solid #E7E8EC;
    /* margin-bottom: 14px; */
}

.btnBlue {
    margin-right: 2%;
    margin-bottom: 10px;
}

.btnWhite {
    margin-right: 2%;
    margin-bottom: 10px;
}

.btnBlue button {
    background-color: #3281AD !important;
    width: 149px;
    height: 40px;
    color: white;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
}

.btnWhite button {
    background-color: #F7F8FA !important;
    width: 149px;
    height: 40px;
    color: #222222;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
}

.passPriceInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.passPriceInput input {
    background: url(../../../assets/dollor.svg) no-repeat left;
    width: 129px;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 20px;
    border-radius: 6px;
}

/* custom transaction part */
.transactionBox {
    margin-top: 40px;
}

.feeHeader {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background-color: #50565C;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 16px;
    position: relative;
    margin-top: 14px;
}


.feeHeader button {
    position: absolute;
    height: 40px;
    top: 0px;
    right: 10px;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 14px;
}

.nameInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.nameInput input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}

.selectFeeTitle {
    color: #6F7985;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 14px;
}

.feeSelectorBtnBlue button {
    box-sizing: border-box;
    width: 100%;
    background-color: #14628E !important;
    height: 31px;
    color: #fff;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    text-align: left;
    margin-bottom: 4px;
}

.feeSelectorBtnWhite button {
    box-sizing: border-box;
    width: 100%;
    background-color: #F7F8FA !important;
    height: 31px;
    color: #222222;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    text-align: left;
    margin-bottom: 4px;
}

.btnLeftRightBox {
    display: flex;
    width: 100%;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 18px;
}

.leftRightBtnBlue {
    flex: 0.5;
}

.leftRightBtnWhite {
    flex: 0.5;
}

.leftRightBtnBlue button {
    width: 100%;
    font-size: 14px;
    height: 36px;
    border: none;
    background-color: #14628E;
    color: white;
}

.leftRightBtnWhite button {
    width: 100%;
    font-size: 14px;
    height: 36px;
    border: none;
    background-color: #F7F8FA;
    color: #222222;
}

.PercentageInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.PercentageInput input {
    box-sizing: border-box;
    background: url(../../../assets/percentage.svg) no-repeat left;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 20px;
    border-radius: 6px;
}

.fixedInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.fixedInput input {
    box-sizing: border-box;
    background: url(../../../assets/dollor.svg) no-repeat left;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 20px;
    border-radius: 6px;
}
.chargePer {
    margin-top: 40px;
}

.addFeeBox {
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
}

.addFeeBox div button {
    padding: 4px 10px;
    background-color: #51BC79;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.addFeeBox div button:hover {
    background-color: #085c27;
    transition: 0.4s;
}


/* add-on part */
.addonHeader {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background-color: #14628E;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 16px;
    margin-top: 14px;
}

.removeBtn {
    position: absolute;
    right: 0;
}

.removeBtn button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    padding-right: 10px;
}

.textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: 183px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding: 5px 10px;
    border-radius: 6px;
}

.addonDescriptionTitle {
    color: #6F7985;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}

.lineBox {
    border-bottom: 1px solid #E7E8EC;
    padding-bottom: 16px;
}

.btnLeftRightAddonBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 18px;
}

.margin {
    margin-top: 16px;
}

.addonFeeHeader {
    box-sizing: border-box;
    width: 100%;
    height: 31px;
    background-color: #50565C;
    margin-top: 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    padding-left: 10px;
    margin-bottom: 16px;
}

.addFeeBtn button {
    background-color: #51BC79;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 0px 5px;
    margin-left: 10px;
}

/* POS option & creditcard & ach & cash part */
.select {
    width: 100%;
    margin-top: 8px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../../assets/arrowDownPassBuilder.svg) no-repeat right center;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 6px;
    margin-bottom: 40px;
}

.marginTop {
    margin-top: 40px;
}

.noneBorder {
    border-bottom: none;
}

.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 40px;
    border-radius: 5px;
}

.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}

.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}

/* text box below charge per day */
.msgBelow {
    position: relative;
    padding: 10px;
    border: 1px solid #8b2d3d;
    border-radius: 5px;
    margin-top: 10px;
    color: #8b2d3d;
    background-color: #eec2c2;
}
.trangleUp {
    position: absolute;
    left: 10px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right:10px solid transparent;
    border-bottom: 15px solid #8b2d3d;
}
.trangleUpSmall {
    position: absolute;
    left: 12px;
    top: -14px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right:8px solid transparent;
    border-bottom: 14px solid #eec2c2;
}
.enableDiscount {
    margin-top: 40px;
}
.dayInput {
    margin-top: 13px;
}
.dayInput label {
    font-size: 16px;
    color: #6F7985; ;
}
.dayInput input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {}

@media screen and (min-width:768px) and (max-width:991px) {}

@media screen and (max-width:767px) {
    .priceInfoBox {
        flex-direction: column;
    }

    .priceTitle {
        font-size: 16px;
    }

    .col {
        flex: 1;
        margin: 30px 0px;
        min-width: 320px;
    }

    .btnBlue button {
        height: 35px;
    }

    .btnWhite button {
        height: 35px;
    }

    .passPriceInput label {
        font-size: 14px;
    }

    .passPriceInput input {
        height: 35px;
    }

    .PercentageInput label {
        font-size: 14px;
    }

    .PercentageInput input {
        height: 35px;
    }

    .fixedInput label {
        font-size: 14px;
    }

    .fixedInput input {
        height: 35px;
    }
    .dayInput label {
        font-size: 14px;
    }
    .dayInput input {
        height: 35px;
    }

    .nameInput label {
        font-size: 14px;
    }

    .nameInput input {
        height: 35px;
    }

    .selectFeeTitle {
        font-size: 14px;
    }

    .select {
        height: 35px;
    }
}