*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.box {
    flex: .7;
    margin-top: 90px;
    display: flex;
    justify-content: center;
}
.flexBox {
    display: flex;
    flex:1;
}
.communityList {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    border:1px solid #D9D9D9;
    border-top: none;
    border-right: none;
    overflow-y: auto;
    position: relative;
    min-height: 400px;
}
.addNewBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.addNewBtn button {
    background-color:#EBEBEC ;
    border: none;
    width: 100%;
    height: 35px;
    color:#144661;
    cursor: pointer;
}
.addNewBtn button:hover {
    transition: .4s;
    background-color: #144661;
    color:white;
}
.communityDetails {
    flex:0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #D9D9D9;
    border-top: none;
}
.infoBox {
    width:60%;
    padding-top: 30px;
}
.input {
    margin-bottom: 8px;
}
.input label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.input input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.checkBox{
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F7F8FA;
    margin-bottom: 8px;
}
.checkBox label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}
.checkBox label div {
    flex: 0;
}
.checkBox label input {
    appearance: none;
    width: 19px;
    height: 19px;
    border: 1px solid #c4c4c4;
    background-color: white;
    cursor: pointer;
}
.checkBox label input:checked {
    background: url(../../assets/checked.svg) no-repeat center;
    background-color: #fff;
}
.inputArea {
    padding-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
}
.checkBoxArea {
    padding-top: 28px;
}
.submitBtn {
    margin-bottom: 40px;
}
.submitBtn button {
    width: 190px;
    height: 50px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    padding: 0 10px;
    font-weight: 500;
}
.submitBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.communityNameBtn {
    height:35px ;
    width: 100%;
    text-align: left;
    padding: 0 5px;
    border: 1px solid #E7E8EC;
    background-color: #fff;
    cursor: pointer;
}
.active {
    height:35px ;
    width: 100%;
    text-align: left;
    padding: 0 5px;
    border: 1px solid #E7E8EC;
    background-color: #8BBBD6;
    font-size: 14px;
    cursor: pointer;
}
.inactive {
    height:35px ;
    width: 100%;
    text-align: left;
    padding: 0 5px;
    border: 1px solid #E7E8EC;
    background-color: #fff;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        flex: .9;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        flex: 1;
    }
    .flexBox {
        flex-direction: column;
     }
     .communityList {
        border-right: 1px solid #D9D9D9;
     }
}
@media screen and (max-width:767px) {
    .box {
        flex: 1;
        margin-top: 40px;
    }
    .flexBox {
       flex-direction: column;
    }
    .communityList {
       border-right: 1px solid #D9D9D9;
    }
    .infoBox {
        width:90%;
        padding-top: 30px;
    }
    .input label {
        font-size: 14px;
    }
    .input input {
        height: 35px;
    }
    .checkBox label {
        height: 35px;
        font-size: 14px;
    }
}