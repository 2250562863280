.box__mainTitleRight {
    flex: 0.15;
    max-width: 424px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 51px;
    margin-left: 30%;
}

.box__subTitle {
    display: flex;
    flex: 0.05;
    max-width: 297px;
    margin-bottom:20px ;
    margin-left: 30%;
}
.box__inputLong {
    display: flex;
    flex: 0.1;
    margin-bottom: 180px;
    margin-left: 30%;
}
.box__inputField{
    flex: 1;
    max-width:337px ;
    min-width: 200px;
}
.box__inputField label {
    width: 100%;
    color: black;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.box__inputField input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.box__btnRight{ 
    display: flex;
    flex: 0.1;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 30%;
    max-width:337px ;
}
.emailBox {
    display: flex;
    font-size: 14px;
    margin-top: 10px;
}
.emailBox button {
    background-color: #D3D9DE;
}
.emailBox button:hover {
    background-color: #a5acb2;
}
.box__btnRightInnerBox {
    margin-left: 10px;
    margin-top: 10px;
}
.allert {
    margin-left: 30%;
    margin-bottom: 10px;
    font-weight: 500;
}
.box__btnRightInnerBox button {
    background-color: #3281AD;
    border-radius: 5px;
    color: white;
}
.box__btnRightInnerBox button:hover {
    transition: .4s;
    background-color: #14628E;
}


@media screen and (min-width:768px) and (max-width:991px) {
    .box__inputField{
        flex: 1;
    }
}
@media screen and (max-width:767px) {

    .box__mainTitleRight {
        margin-left: 20px;
    }
    .box__subTitle {
        margin-left: 20px;
    }
    .box__inputLong{
        margin-left: 20px;
    }
    .box__btnRight{ 
        justify-content: flex-start;
        margin-left: 20px; 
    }
    .allert {
        margin-left:20px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .box__inputField{
        flex: .9;
    }

}