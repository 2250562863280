* {
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .container {
        justify-content: center;
    }
}