*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.documentsBox {
    margin-top: 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
}
.documentsTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    margin-left: 10px;
}
.documentsTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
}
.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.addDocumentBtn button{
    padding: 4px 10px;
    background-color:#51BC79;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.addDocumentBtn button:hover {
    background-color: #085c27;
    transition: 0.4s;
}
.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .documentsBox {
        margin-top: 30px;
        width: 100%;
    }
    .documentsTitle {
        font-size: 16px;
    }
}
