.pass {
    width: 320px;
    background-color: #fff;
    filter: drop-shadow(0 0 3px rgba(0,0,0,.3));
    border: 1px solid rgb(226, 224, 224);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 20px;
    margin-bottom: 10px;
}
.logo {
    width: 60px;
    height: 60px;
    background-color:#14628E ;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
}
.passName {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    margin: 5px 0px;
}
.timeBox {
    width: 100%;
    background-color: #F8F8F8;
    border-radius: 10px;
    margin-top: 5px;
}
.validFromBox {
    margin-top: 8px;
    padding-left: 7px;
    padding-bottom: 5px;
    border-bottom: 1px dashed #c4c4c4;
}
.validFrom {
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #2E3032;
}
.validFromItems {
    font-weight: 700;
    color: black;
    font-size: 14px;
}
.validToBox {
    margin-top: 8px;
    padding-left: 7px;
    padding-bottom: 5px;
}
.infoBox {
    width: 100%;
    background-color: #F8F8F8;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    overflow: hidden;
}
.Row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    border-bottom: 1px dashed #c4c4c4;
}
.Row:last-child {
    border: none;
}
.rowBox .Row:last-child {
    border-bottom: 1px dashed #c4c4c4;
}
.info {
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    width: 45%;
    font-size: 14px;
    word-break: break-all;
}
.infoLong{
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    width: 100%;
    font-size: 14px;
    word-break: break-all;
}
.infoTitle {
    color: #2E3032;
}
.infoItem {
    color: black;
    font-weight: 700;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .pass {
        box-sizing:border-box ;
        max-width: auto;
        width: 100%;
        padding-top: 11px;
        padding-bottom: 14px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 0px;
        margin: 0;
        margin-bottom: 10px;
    }
}