.box {
    box-sizing: border-box;
    padding: 0px 50px;
    padding-top: 12px;
    padding-bottom: 40px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
}
.boxWithoutLine {
    box-sizing: border-box;
    padding: 0px 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
}
.boxTitle {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #E7E8EC;
    font-size: 18px;
    text-align: center;
    color: #222222;
}
.boxTitleWithoutLine {
    position: relative;
    width: 100%;
    font-size: 18px;
    text-align: center;
    color: #222222;
}
.backBtn {
    position: absolute;
    left: 0;
    top: 0px;
    color: #6F7985;
    font-size: 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-left: 15px;
    background: url(../../assets/backArrow.svg) no-repeat left center;
}
.positonArea {
    position: absolute;
    display: inline-block;
    right: 0;
    font-size: 14px;
    color: #6F7985;
}
.communityNameBox {
    padding: 5px;
    background-color:#F7F8FA ;
    border-radius: 2px;
}
.portalNameBox {
    padding: 5px;
    background-color:#F7F8FA ;
    border-radius: 2px;
}
.positionArrow {
    margin: 0px 5px;
}
.reviewPassTitle {
    color:#14628E;
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    font-weight: 500;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        width: 100%;
        padding: 10px 10px 40px 10px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        width: 100%;
        padding: 10px 10px 40px 10px;
    }
}
@media screen and (max-width:767px) {
    .box {
        width: 100%;
        padding: 10px 10px 40px 10px;
        box-sizing: border-box;
        min-width: 320px;
        margin-top: 10px;
    }
    .boxWithoutLine {
        border-radius: 6px;
        min-width: 320px;
        margin-top: 10px;
    }
    .boxTitle {
        font-size: 14px;
    }
    .boxTitleWithoutLine {
        font-size: 14px;
    }
    .positonArea {
        position: absolute;
        display: inline-block;
        right: 0;
        top: 31px;
        font-size: 13px;
        color: #6F7985;
    }
    .communityNameBox {
        padding: 3px;
        background-color:#F7F8FA ;
        border-radius: 2px;
    }
    .portalNameBox {
        padding: 3px;
        background-color:#F7F8FA ;
        border-radius: 2px;
    }
    .reviewPassTitle {
        color:#14628E;
        text-align: center;
        font-size: 15px;
        margin-top: 24px;
        font-weight: 500;
    }
}