.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.innerBox {
    width: 50%;
}
.nameInput label {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.nameInput input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.createBtn button {
    width: 190px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.createBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .innerBox {
        width: 99%;
    }
}