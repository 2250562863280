.selectLabel {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.select {
    margin-top: 8px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../assets/arrowDownPassBuilder.svg) no-repeat right center;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 6px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .selectLabel {
        font-size: 14px;
    }
    .select {
        height: 35px;
    }
}