.box {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0px 50px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
}
.createBox {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0px 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #DCE1E5;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.invisiableBox {
    flex: .2;
}
.warning {
    background: url(../../../assets/alert.svg) no-repeat left center;
    padding-left: 25px;
    font-size: 16px;
    font-weight: 400;
}
.btn {
    flex: .2;
  display: flex;
  align-items: center;
}
.btn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
.btn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.boxTop {
    box-sizing: border-box;
    width: 100%;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        box-sizing: border-box;
        margin-top: 10px;
        padding: 0px 10px;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .invisiableBox {
        display: none;
    }
}
@media screen and (max-width:767px) {
    .box {
        box-sizing: border-box;
        margin-top: 10px;
        padding: 0px 10px;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .invisiableBox {
        display: none;
    }
    .createBox {
        box-sizing: border-box;
        margin-top: 10px;
        padding: 0px 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        background-color: #fff;
        border: 2px solid #DCE1E5;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .warning {
        font-size: 14px;
        font-weight: 400;
    }
}
