* {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}

.box {
    flex: .7;
    margin-top: 90px;
    display: flex;
    justify-content: center;
}

.communitySelectBox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innerBox {
    width: 50%;
}

.locationTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    margin-bottom: 20px;
}

.select {
    padding-bottom: 30px;
}

.uploadBox {
    border-top: 1px solid #D3D9DE;
    width: 100%;
    padding-top: 30px;
    display: flex;
}

.flexBox {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.upload {
    flex: .5;
    display: flex;
    justify-content: center;
}

.uploadedTitle {
    padding: 5px 10px;
    background-color: #14628E;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    margin-top: 20px;
}

.uploaded {
    display: flex;
    align-items: center;
}

.uploadedItem {
    font-size: 13px;
    color: #3C3A3A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.cancel {
    width: 20px;
    height: 20px;
    background: url(../../assets/cancel.svg) no-repeat center;
    border: none;
    cursor: pointer;
    margin-left: 4px;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
    .innerBox {
        width: 80%;
    }

    .communitySelectBox {
        margin-top: 20px;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        flex: .8;
    }

    .innerBox {
        width: 80%;
    }

    .communitySelectBox {
        margin-top: 20px;
    }
}

@media screen and (max-width:767px) {
    .box {
        flex: 1;
        margin-top: 37px;
    }

    .innerBox {
        width: 99%;
    }

    .locationTitle {
        font-size: 16px;
    }

    .flexBox {
        flex-direction: column;
    }

    .upload {
        margin-bottom: 18px;
    }
}