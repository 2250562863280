.dashedBox {
    border: 1px dashed #c4c4c4;
    padding: 50px 35px 35px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.dragTitle {
    font-size: 18px;
    font-weight: 500;
    color: #14628E;
}
.language {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}
.browseBtn button {
    width:204px;
    height: 39px;
    margin-top: 96px;
    background-color: #14628E;
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: 500;
}
.browseBtn button:hover {
    background-color: #14628E;
    transition: 0.4s;
    color: white;
}
.cloudImg {
    margin-bottom: 23px;
}
.upoloading {
    margin-top: 20px;
}
.uploadingTitle {
    padding: 5px 10px;
    background-color: #14628E;
    border-radius: 4px;
    color: white;
    font-weight: 500;
}
.uploadingProcess {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}
.uploadingItem {
    font-size: 13px;
    color: #3C3A3A;
    display: flex;
    align-items: center;
}
.cancel {
    width: 20px;
    height: 20px;
    background: url(../../assets/cancel.svg) no-repeat center;
    border: none;
    cursor: pointer;
    margin-left: 4px;
}
.line {
    width: 100%;
    height: 5px;
    background-color: #c4c4c4;
    margin: 3px 0px;
}