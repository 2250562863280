* {
    margin: 0;
    padding: 0;
   }
   .container {
       flex: 1;
       display: flex;
       flex-direction: row;
       justify-content: center;
       min-width: 320px;
       padding-bottom: 300px;
   }
.box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    min-width: 320px;
    margin-top: 104px;
    max-width: 1700px;
}

.box__innerBox {
    margin: 30px 10px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {

    .box__innerBox {
        flex: 0.4;
    }
}
@media screen and (max-width:767px) {
    .box {
        flex-direction: column;
        min-width: 320px;
        flex: 0.6;
        margin-bottom: 20px;
        margin-top: 60px;
    }
    .box__innerBox {
        margin-top: 10px;
        
    }
    
    .box__innerBox {
        margin: 10px 30px;
    }
 

}