* {
 margin: 0;
 padding: 0;
}
.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
    padding-bottom: 300px;
}


.box {
    display: flex;
   flex: 0.8;
   flex-direction: row;
    border-radius: 20px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    margin-top: 180px;
}
.box__left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.5;
    padding-top: 56px;
    padding-left: 107px;
    padding-bottom: 77px;
    border-radius: 20px 0 0 20px;
    
}

.box__titleBoxLeft {
    display: flex;
   flex: 0.15;
   flex-direction:column;
}
.box__right {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    position: relative;
    padding-top: 56px;
    padding-right: 107px;
    padding-bottom: 77px;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
}


.box__mainTitleLeft {
    max-width: 358px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 6px;
    
}
.box__paragraph {
    max-width: 424px;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 150%;
}
.box__subTitle {
    display: flex;
    flex: 0.05;
    margin-bottom:24px ;
}

.box__nameBox {
   display: flex;
   flex-wrap: wrap;
   flex: 0.1;
}

.box__inputShort{
   flex: 0.3;
   margin-bottom: 20px;
}
.invisbleBox{
    flex:0.1;
    min-width: 17px;
}
.inputLong{
    display: flex;
    flex: 0.1;
    flex-direction: column;
}
.box__inputLong {
    display: flex;
    flex: 0.1;
    margin-bottom: 20px;
}
.box__inputField{
    flex: 0.7;
}
.box__btnLeft{
    display: flex;
    flex: 0.1;
    margin-top: 48px;
}
.box__btnLeftInnerBox {
    display: flex;
    justify-content: flex-end;
    flex:0.7;
}

.box__mainTitleRight {
    display: flex;
    justify-content: flex-end;
    flex: 2;
    align-items: flex-end;
    font-size: 24px;
    font-weight: 600;
    
}
.box__mainTitleRightInnerBox{
    flex: 0.7;
}
.box__btnRight {
    display: flex;
    justify-content: flex-end;
    flex: 0.1;
    margin-top: 48px;
}
.box__btnRightInnerBox{
    flex: 0.7;
}
.box__img {
    position: absolute;
    right: -100px;
    top: -100px;
    
}
.alert {
    display: flex;
    margin-right: 30%;
}
.alertBox {
    flex: 1;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) {
  
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
    
       flex: 1;
       flex-direction: row;
        margin-top: 180px;
    }

    .box__right {
        padding-top: 56px;
        padding-right: 87px;
    }
    .box__mainTitleRight {
        margin-left: 20px;
    }
    .box__btnRight {
        margin-left: 20px;
    }
}
@media screen and (max-width:767px) {
    .box {
        flex-direction: column!important;
        flex-wrap: wrap;
       flex: 1;
       flex-direction: row;
        border-radius: 0px;
        margin-top: 44px;
    }
    .box__left {
        flex: 1;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 77px;
        border-radius: 0;
    }
    .box__right {
        flex: 1;
        padding-top: 56px;
        padding-right: 20px;
        padding-bottom: 77px;
        border-radius: 0;
    }
    .box__mainTitleLeft {
       margin-left: 20px;
        
    }
    .box__paragraph {
       margin-left: 20px;
    }
    .box__subTitle {
        margin-left: 20px;
    }
    .box__nameBox {
        margin-left: 20px;
     }
     .inputLong{
        margin-left: 20px;
    }
    .box__mainTitleRight {
        margin-left: 40px;
        justify-content: flex-start;
    }
    .box__btnLeft{
        margin-left: 20px;
    }
    .box__btnLeftInnerBox {
        justify-content: flex-start;
    }
    
    .box__btnRight {
        margin-left: 40px;
        margin-top: 48px;
        justify-content: flex-start;
    }
    .box__img {
        display: none;
        
    }
    .alert {
        margin-right: 30%;
        margin-left: 20px;
    }
    .alertBox {
        flex: 1;
        min-width: 181.98px;
    }
    .container {
        padding-bottom: 0px;
    }
}
