* {
    padding: 0;
    margin: 0;
}
a {
    text-decoration: none;
}
.cardBox {
    flex:1;
    display: flex;
    justify-content: center;
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    height: 300px;
    width: 277px;
    min-width: 230px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
}
.title {
    flex: 0.14;
    font-size: 20px;
    font-weight: 400;
    margin-top: 21px;
    color: #222222;
}
.description {
    font-size: 14px;
    font-weight: 400;
    max-width: 270px;
    margin-top: 0px;
    height: 40px;
    line-height: 150%;
    color: #222222;
}
.btnBox {
    width: 100%;
    flex: 0.41;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
}
.btn {
    height: 40px;
    width: 90%;
    border-radius: 5px;
    font-size: 16px;
    outline-style: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    background-color:#3281AD ;
    color: white;
}
.btn:hover {
    background-color: #144661;
    transition: 0.4s;
}
.image {
    margin-top: 20px;
    flex: 0.33;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .btn {
        font-size: 16px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .card {
      height: 280px;
    }
    .image {
        margin-top: 20px;
        width: 80px;
    }
    .title {
        margin-top: 20px;
    }
    .btnBox {
       margin-top: 20px;
    }
    .description {
        font-size: 12px;
       max-width: 200px;
    }
    .btn {
        height: 35px;
        font-size: 16px;
    }
}
@media screen and (max-width:767px) {
    .card {
        height: 260px;
        width: auto;
      }
      .image {
          margin-top: 20px;
          width: 80px;
      }
      .title {
          margin-top: 5px;
          font-size: 20px;
      }
      .description {
        font-size: 12px;
        max-width: 200px;
    }
    .btnBox {
        margin-top: 20px;
     }
     .btn {
        height: 35px;
        font-size: 16px;
    }

}