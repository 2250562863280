.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    padding-top: 90px;
}
.wrapperShortPadding {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    padding-top: 70px;
}
.linkBox {
    display: flex;
    flex-direction: column;
    flex: .15;
    height: 600px;
    padding-left: 10px;
    margin-left: 20px;
}
.stepsLine {
    position: relative;
    width: 1px;
    height: 600px;
    background-color:#8D9EAE ;
}
.stepsDown button {
    font-size: 18px;
    font-weight: 400;
    color: #2A5885;
    border: none;
}
.linkItemsBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
}
.linkItemsBox a {
    color: #8D9EAE;
    font-size: 14px;
}
.pages {
    flex: .7;
}
.pagesLarge {
    flex: 1;
    margin: 0px 20px;
}
.stepsBox {
    display: flex;
}
.rectangle {
    position: absolute;
    bottom: -25px;
    left: -10px;
    width: 20px;
    height: 36px;
    border: 1px solid #8D9EAE;
}
.arrow {
   position: absolute;
   bottom: -5px;
   left: -4px;
   width: 0;
   height: 0;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 10px solid #8D9EAE;
}
/* === vvv === TODO: replace temporary css */

.activeLink p {
   color: #51BC79;
   font-weight: 500;
}
.activeLink .line {
    top: 20px;
    height: 3px;
    width: 80%;
    background-color: #51BC79;
    margin-top: 6px;
    border-radius: 8px;
}
.completeLink p {
    color: #2A5885;
}

/* === ^^^ === */
.invisbleBox {
    flex: .15;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .pages {
        flex: .8;
    }
    .linkBox {
        display: flex;
        flex-direction: column;
        flex: .15;
        height: 600px;
        margin-left: 0px;
        padding-left: 10px;
    }
    .linkBox {
        flex: .2;
    }
    .invisbleBox {
        flex: 0;
    }
}
@media screen and (max-width:767px) {
    .wrapper {
        flex-direction: column;
    }
    .wrapperShortPadding {
        padding-top: 33px;
    }
    .stepsBox {
        position: relative;
        display: flex;
    }
    .linkBox {
        display: flex;
        flex-direction: column;
        flex: .15;
        margin-top: 20px;
        height: 600px;
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 0px;
    }
    .stepsLine {
        position: relative;
        width: 0px;
        height: auto;
        background-color:#8D9EAE ;
    }
    .stepsDown button {
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        color:black ;
        border: none;
        padding-right: 30px;
        background: url(../../../assets/selectDown.svg) no-repeat right center;
        background-color: #e5e8eb;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
        margin-top: 30px;
        border-radius: 10px;
        padding: 8px 0px;
    }
    .stepsUp button {
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        color:white ;
        border: none;
        padding-right: 30px;
        background: url(../../../assets/selectUp.svg) no-repeat right center;
        background-color:#14628E ;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
        margin-top: 30px;
        border-radius: 10px;
        padding: 8px 0px;
    }
    .linkItemsBox {
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 0px;
        background-color: #fff;
        height: 400px;
        padding: 10px;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
        z-index: 1;
        border-radius: 5px;
    }
    .linkItemsBox a {
        color: #8D9EAE;
        font-size: 14px;
        margin-right: 10px;
    }
    .pages {
        flex: .7;
    }
    .pagesLarge {
        flex: 1;
        margin: 0px ;
    }
    .stepsBox {
        display: flex;
    }
    .rectangle {
       display: none;
    }
    .arrow {
       display: none;
    }
    .wrapper {
        padding-top: 0px;
    }
}