*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.employeeInfoBox {
    margin-top: 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
}
.employeeInfoTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    margin-left: 10px;
}
.employeeInfoTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
}
.optionBox {
    margin-left: 10px;
}
.optionTitle {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}
.optionBtnsBox{
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid #E7E8EC;
    margin-bottom: 14px;
}
.btnBlue {
    margin-right: 1%;
}
.btnWhite {
    margin-right: 1%;
}
.btnBlue button {
    background-color: #3281AD!important;
    width: 149px;
    height: 40px;
    color: white;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
}
.btnWhite button {
    background-color: #F7F8FA!important;
    width: 149px;
    height: 40px;
    color: #222222;
    border: 1px solid #D3D9DE;
    border-radius: 6px;
}
.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.dropContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.employeeDocArea {
    border-top: 1px solid #E7E8EC;
    padding-top: 20px;
}
.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .employeeInfoTitle {
        font-size: 16px;
    }
    .optionBtnsBox{
        flex-direction: column;
    }
    .btnBlue {
        margin-right: 1%;
        margin-bottom: 10px;
    }
    .btnWhite {
        margin-right: 1%;
        margin-bottom: 10px;
    }
    .optionTitle {
        font-size: 14px;
    }
    .btnBlue button {
        height: 35px;
    }
    .btnWhite button {
        height: 35px;
    }
}
