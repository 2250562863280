*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.durationBox {
    margin-top: 30px;
    width: 60%;
    display: flex;
    flex-direction: column;
}
.durationTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.durationTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
}
.activeBox {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 10px 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.activeBox  .timeInstruction {
    font-size: 16px;
    color:#3C3A3A ;
    font-weight: 400;
}
.activeBox .dayInput {
    margin-top: 13px;
}
.activeBox .dayInput label {
    font-size: 16px;
    color: #3C3A3A ;
}
.activeBox .dayInput input {
    width: 150px;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.circleActive {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    background: url(../../../assets/pick.svg) no-repeat center;
    cursor: pointer;
}
.inactiveBox {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 10px 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inactiveBox .timeInstruction {
    font-size: 16px;
    color:#949AA1 ;
    font-weight: 400;
}
.inactiveBox .dayInput {
    margin-top: 13px;
}
.inactiveBox .dayInput label {
    font-size: 16px;
    color: #949AA1  ;
}
.inactiveBox .dayInput input {
    width: 150px;
    margin-top: 8px;
    height: 40px;
    background: #F3F4F7;
    border: none;
    outline-style: none;
    padding-left: 10px;
    border-radius: 6px;
}
.circleInactive {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    background-color:#EDEEF0 ;
    cursor: pointer;
}
.marginBottom {
    margin-bottom: 23px;
}
.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .durationBox {
        margin-top: 20px;
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .durationBox {
        margin-top: 20px;
        width: 80%;
    }
}
@media screen and (max-width:767px) {
    .durationBox {
        box-sizing: border-box;
        padding: 0 10px;
        margin-top: 30px;
        width: 100%;
    }
    .durationTitle {
        font-size: 16px;
    }

    .activeBox  .timeInstruction {
        font-size: 14px;
    }

    .activeBox .dayInput label {
        font-size: 14px;
    }
    .activeBox .dayInput input {
        height: 35px;
    }

    .inactiveBox .timeInstruction {
        font-size: 14px;
    }
    .inactiveBox .dayInput label {
        font-size: 14px;
    }
    .inactiveBox .dayInput input {
        height: 35px;
    }
    .marginBottom {
        margin-bottom: 23px;
    }
}
.disabled {
    pointer-events: none;
    opacity: 0.7;
}