* {
    margin: 0;
    padding: 0;
   }
.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
    padding-bottom: 300px;
}
.box {
    display: flex;
   flex: 0.8;
   flex-direction: row;
    border-radius: 5px;
    margin-top: 130px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
}

.box__right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.5;
    padding-top: 208px;
    padding-right: 129px;
    padding-bottom: 78px;
    border-radius: 0 5px 5px 0;
}
.box__left {
    display: flex;
    flex-direction: column;
    position: relative;
    flex:0.5;
    overflow: hidden;
    padding-top: 56px;
    padding-left: 129px;
    padding-bottom: 78px;
    border-radius: 5px 0 0 5px;
    background-image: linear-gradient(#3281AD,#14628E);
}


.box__img {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 400px;
}




@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) {
  
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
    
        flex: 1;
        flex-direction: row;
         margin-top: 130px;
     }
     .box__right {
        padding-top: 56px;
        padding-right: 87px;
    }
    .box__left{
        padding-left: 87px;
    }
    
}
@media screen and (max-width:767px) {
    .box {
        flex-direction: column!important;
        flex-wrap: wrap;
       flex: 1;
       flex-direction: row;
        border-radius: 0px;
        margin-top: 44px;
    }
    .box__right {
        flex: 1;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 77px;
        border-radius: 0;
        order: -1;
    }
    .box__left {
        display: none;
    }
    
    .box__img {
        display: none;
        
    }
    .container {
        padding-bottom: 0px;
    }
    
}