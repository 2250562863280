* {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.passLocationBox {
    margin-top: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.passLocationTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.passLocationTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
}

.selectLabel {
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.select {
    margin-top: 8px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../../assets/arrowDownPassBuilder.svg) no-repeat right center;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 6px;
}

.communityBtnBox {
    width: 100%;
    margin-top: 40px;
}

.selectPortalTitle {
    color: #6F7985;
    font-size: 16px;
    font-weight: 400;
}

.roleBtnBox {
    display: flex;
    flex-wrap: wrap;
}

.roleBtn {
    position: relative;
    background-color: #fff;
    border: none;
    width: 30%;
    height: 67px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 8px;
    cursor: pointer;
    color: #14628E;
    display: flex;
    align-items: center;
    min-width: 140px;
}

.btnContent {
    display: flex;
    align-items: center;
    margin-left: 10%;
}

.text {
    margin-left: 10px;
    font-weight: 500;
}

.colorLine {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px 0px 0px 6px;
    width: 10px;
    height: 100%;
}

.colorGuest {
    background-color: #6798B4;
}

.colorVendor {
    background-color: #85BAD9;
}

.colorResident {
    background-color: #93BDA2;
}

.actionBtnBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.actionBtn {
    width: 48%;
}

/* .actionBtnBox a {
    width: 48%;
} */
.actionBtn button {
    width: 100%;
    background-color: #fff;
    border: none;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 8px 0px;
    cursor: pointer;
    color: #222222;
}

.actionBtn button:hover {
    background-color: #14628E;
    transition: 0.4s;
    color: white;
}

.btnContent svg {
    fill: #14628E;
}

.active {
    background-color: #14628E;
    color: white;
}

.active .btnContent svg {
    fill: white;
}


@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
    .passLocationBox {
        margin-top: 20px;
        width: 80%;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .passLocationBox {
        margin-top: 20px;
        width: 80%;
    }

}

@media screen and (max-width:767px) {
    .passLocationBox {
        margin-top: 20px;
        width: 100%;
    }

    .passLocationTitle {
        font-size: 16px;
    }

    .selectLabel {
        font-size: 14px;
    }

    .select {
        height: 35px;
    }

    .selectPortalTitle {
        font-size: 14px;
    }

    .roleBtnBox {
        flex-direction: column;
    }

    .roleBtn {
        height: 50px;
    }

    .btnContent svg {
        width: 35px;
    }

    .text {
        font-size: 14px;
    }

    .actionBtnBox {
        display: flex;
        flex-direction: column;
    }

    .actionBtnBox a {
        width: 100%;
        margin-bottom: 10px;
    }
}