*{
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-width: 320px;
}
.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formReview {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.instructionBox {
    margin-top: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
}
.instructionTitleBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.instructionTitle {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
}
.passInstructionLabel {
    margin-top: 20px;
    width: 100%;
    color: #6F7985;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.passInstructionTextArea {
    box-sizing: border-box;
    width: 100%;
    min-height: 217px;
    margin-top: 8px;
    height: 40px;
    background-color: #F7F8FA;
    border: 1px solid #D3D9DE;
    outline-style: none;
    padding: 5px 10px;
    border-radius: 6px;
}
.continueBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.continueBtn button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #14628E;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}
.continueBtn button:hover {
    background-color: #144661;
    transition: 0.4s;
}
.editBtn {
    position: absolute;
    right: 0;
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.editBtn button {
    width: 70px;
    height: 24px;
    background-color: #14628E;
    border: none;
    color: white;
    border-radius: 3px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .instructionBox {
        margin-top: 20px;
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .instructionBox {
        margin-top: 20px;
        width: 80%;
    }
}
@media screen and (max-width:767px) {
    .instructionBox {
        box-sizing: border-box;
        padding: 0 10px;
        margin-top: 30px;
        width: 100%;
    }
    .instructionTitle {
        font-size: 16px;
    }
    .passInstructionLabel {
        margin-top: 20px;
        width: 100%;
        color: #6F7985;
        font-size: 14px;
        display: flex;
        flex-direction: column;
    }
    .passInstructionTextArea {
        width: 99%;
    }
}