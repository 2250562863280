.dragItemBox {
    position: relative;
    box-sizing: border-box;
    width: 95%;
    height: 37px;
    background-color: #fff;
    margin: 3px 0px;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
    color:#3C3A3A;
    cursor: pointer;
}
.exitBtn button {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 30px;
    height: 37px;
   background: url(../../assets/dragItemExit.svg) no-repeat center;
   border-color: transparent;
}
.locked {
    background-color: #EEEEEE;
    opacity: 0.5;
    cursor: not-allowed;
}
